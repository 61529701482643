import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Row, Col } from 'react-bootstrap';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useDispatch, useSelector } from 'react-redux';
import { accountMessagesSelector, accountProfileSelector } from 'state/account/account.selectors';
import { accountFetchMessagesRoutine } from 'state/account/account.routines';
import Button from 'ui-kit/button/button';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

import './messages.style.scss';
import { defineMessageAuthor, messageOptions } from 'const/options';
import { navigate } from 'gatsby';
import { toNumber } from 'lodash';

const InboxMessage = ({ message, handleClick }) => {
    const profileObject = useSelector(accountProfileSelector);
    const isCaregiver = profileObject?.isCaregiver;
    const messageDate = new Date(message.Date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
    const messageType = messageOptions.find((option) => option.value === message.messageSource);

    return (
        <button data-ga-form-name={'message-list'} onClick={handleClick}>
            <Row className="message">
                <Col xs={{ span: 6, order: 1 }} lg={3} xl={2}>
                    <div className={`message--status state-${message?.Action?.toLowerCase()} text-center mb-2 mb-lg-0`}>
                        {message.Action}
                    </div>
                </Col>
                <Col xs={{ span: 6, order: 3 }} lg={{ span: 5, order: 2 }} xl={7}>
                    <div className={'message--source text-secondary'}>{messageType.label}</div>
                    <div className={'message--subject'}>{message.Subject}</div>
                    {isCaregiver && <div className={'message--author'}>Re: {defineMessageAuthor(message.Author)}</div>}
                </Col>
                <Col
                    xs={{ span: 6, order: 2 }}
                    lg={{ span: 3, order: 3 }}
                    xl={2}
                    className="d-flex align-items-center justify-content-end"
                >
                    <div className={'message--date text-secondary'}>
                        <small>{messageDate}</small>
                    </div>
                </Col>
                <Col xs={{ span: 6, order: 4 }} lg={1} xl={1} className="d-flex align-items-center justify-content-end">
                    <div className={'message--read-cta'}>
                        <ChevronIcon direction="right" />
                    </div>
                </Col>
            </Row>
        </button>
    );
};

const MessagesSection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messages = useSelector(accountMessagesSelector);
    const profileObject = useSelector(accountProfileSelector);
    const [customScrollPos, setCustomScrollPos] = useState(undefined);
    const askThePharmMessages = messages?.askThePharm;
    const contactUsMessages = messages?.contactUs;
    const combinedMessages =
        askThePharmMessages !== undefined && contactUsMessages !== undefined
            ? [...askThePharmMessages, ...contactUsMessages]
                  .sort((a, b) => {
                      const firstDate = new Date(a.Date);
                      const secondDate = new Date(b.Date);
                      return secondDate.getTime() - firstDate.getTime();
                  })
                  .map((message) => {
                      const messageSource = Object.prototype.hasOwnProperty.call(message, 'PharmReply')
                          ? 'Ask The Pharmacist'
                          : 'Contact Us';
                      return {
                          ...message,
                          messageSource
                      };
                  })
            : undefined;
    const showLoading = combinedMessages === undefined;

    const getLatestSavedScrollPosition = (pathname: string): number => {
        if (typeof sessionStorage !== 'undefined') {
            let n = sessionStorage.length;
            let i = 0;
            const partialKey = `@@scroll|${pathname}|`;
            let results = [];
            while (++i < n) {
                const key = sessionStorage.key(i);
                if (key?.includes(partialKey)) {
                    results.push(key);
                }
            }
            if (results.length === 0) {
                return 0;
            }
            results.sort();
            const foundScollPosition = sessionStorage.getItem(results[results.length - 1]);
            return foundScollPosition ? toNumber(foundScollPosition) : 0;
        } else {
            return 0;
        }
    };

    if (customScrollPos === undefined) {
        setCustomScrollPos(getLatestSavedScrollPosition('/secure/profile/messages'));
    }

    useEffect(() => {
        if (profileObject) {
            dispatch(accountFetchMessagesRoutine.trigger());
        }
    }, [profileObject]);

    const customScrollPosition = () => {
        if (props?.location?.state?.useCustomScrollPos) {
            if (customScrollPos && customScrollPos < document.body.scrollHeight) {
                window.scrollTo(0, customScrollPos);
            }
        }
    };

    const handleFetchMessageThread = (message: any) => {
        if (typeof window !== 'undefined') {
            message.messageSource === 'Ask The Pharmacist'
                ? navigate(`/secure/profile/messages/ask-pharm?header_id=${message.HeaderID}`)
                : navigate(`/secure/profile/messages/contact-us?header_id=${message.HeaderID}`);
        }
    };

    const composeMessage = () => {
        navigate('/secure/profile/messages/compose');
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.messages.eyebrowText`)}
            title={t(`pages.profile.messages.title`)}
            heading={t(`pages.profile.messages.heading`)}
        >
            <Button
                label={t(`pages.profile.messages.compose`)}
                className="no-min-width btn-compose-message"
                type="button"
                variant="primary"
                onClick={composeMessage}
            />
            <div className="messages-container">
                {combinedMessages && combinedMessages.length === 0 && (
                    <div className="message--no-messages text-center">
                        <p className="w-75 mx-auto">{t(`pages.profile.messages.noMessages`)}</p>
                        <p className="d-flex justify-content-center mt-5">
                            <small>
                                <Button
                                    variant="text-blue"
                                    label={t(`pages.profile.messages.composeMessage`)}
                                    type="button"
                                    onClick={composeMessage}
                                />
                            </small>
                        </p>
                    </div>
                )}
                <LoadingMessage isVisible={showLoading} text={t('pages.profile.messages.loading')} />
                {combinedMessages && combinedMessages.length !== 0 && (
                    <>
                        {combinedMessages.map((message) => (
                            <InboxMessage
                                message={message}
                                key={`message-id-${message.HeaderID}`}
                                handleClick={() => handleFetchMessageThread(message)}
                            />
                        ))}
                        {customScrollPosition()}
                    </>
                )}
            </div>
        </ProfileLayout>
    );
};

export default MessagesSection;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
